module.exports = {
  commen: {
    steemAccoutPlaceHolder: "Cuenta Steem",
    hiveAccountPlaceHolder: "Cuenta Hive",
    loginByKeychain: "Ingresa con Keychain",
    loging: "Ingresando...",
    crowdstaking: "Staking",
    optional: "(Opcional)",
    connectMetamask: 'Conecta Metamask',
    delegateFee: 'Fee por delegación',

    apy: "APR",
    balance: "Balance",
    max: "Máximo",
    complete: "Completado",
    now: "Ahora",
    poster: "Postergar",
    more: 'Más',
    detail:'Detalle',
    activities: 'Actividades',
    administor: 'Administrador',
    joinDate: 'Día de registro',
    noData: 'Sin datos',
    pageNotFound: "Página no encontrada!",
    backHome: "Volver al Inicio",
    amount: "Monto",
    earned: 'GANADO',
    voted: "VOTAR",
    staked: "STAKED",
    delegated: "DELEGADO",
    totalStake: 'Total Staking',
    voters: 'Votantes',
    stakers: 'Stakers',
    summary: 'Total',
    weeks: 'Semanas',
    or: 'O',

    whitePaper: "White Paper",
    nps: "Nutbox Sistema de propuestas",
    contract: "Contrto",
    social: "Redes sociales",
    docs: "Documentos",
    discord: "Discord",
    blog: "Blog",
    contractAddr: "Dirección de Contrato",
    dashboard: "Inicio",
    auditReport: "Reporte de auditoría",
    language: "Lenguaje",
    zh: "中文",
    en: "English",
    kr: "한국어",
    my: "Malaysia",
    tr: "Türkçe",
    es: "Español",
    jp: "日本語",
    goToHome: "Regresar a la página",
    home: 'Inicio',
  },
  desc: {
    slogan: 'Crea tu economía de Staking en minutos',
    featuredCommunity: 'Comunidades',
    aboutNutbox: 'Sobre Nutbox',
    totalValue: 'Valor total de los activos',
    disStrategy: 'Estrategia de distribución',
    adminOnly: 'Solo Administrador',
    youWillReceive: 'Va a recibir: ',
    iso: 'ISO: Initial Staking Offering',
    isoDetail: 'Tokens de comunidad acuñados bajo Staking. Alto APR, Sin riesgo.',
    erc1155Desc: 'Haz Stake con tus ERC1155 tokens y genera ganacias.',
    nutPower: 'El Nut power representa la gobernanza de Nutbox. Es generado por el farming: Haz Stake de algunos tokens y comienza a ganar.',
    farming: 'Solo haz stake de algunos tokens y comienza a ganar.',
    assetTip1: 'Si quieres usar un token existente',
    assetTip2: 'Si quieres crear un nuevo token',
    assetTipe3: `*Puede preminar algunos tokens para iniciar su comunidad,
    el resto de tokens se acuñarán del contrato bloque por bloque.`
  },
  operation: {
    withdraw: "Retirar",
    harvest: "Cosechar",
    approveContract: "Aprobar Contrato",
    approveCommunity: 'Aprobar Comunidad',
    approvePool: 'Aprobar Pool',
    connectMetamask: "Conectar Metamask",
    change: 'Cambiar',
    search: "Buscar",
    userlogin: "Ingresar",
    uploadLogo: "Subir logo",
    uploadPoster: "Subir poster",
    sign: "ingresa y sube",
    hide: 'Ocultar',
    cancel: "Cancelar",
    commit: "Hacer",
    complete: "Completar",
    confirm: "Confirmar",
    create: "Crear",
    add: "Añadir",
    update: "Actualizar",
    delegatecharge: "Delegar Cargo",
    login: "Ingresar",
    logout: "Cerrar Sesión",
    input: "Entrada",
    ok: 'OK',
    copy: "Copiar",
    edit: "Editar",
    charge: 'Chargar',
    redeem: "Redimir",
    enter: 'Entrar',
    approve: 'Aprovar',
    grant: 'Garantia',
    manageCommunity: "Maneja tu comunidad",
    exploreCommunity:'Explora Comunidades',
    save: 'Guardar',
    grantMintRole: 'Acuñar token en el contrato de Nutbox',
    configurePoolRatio: 'Configurar Pool de Delegación',
    addDelegatePool: 'Añadir Pool para delegar',
    addPool: 'Añadir pool',
    claim: 'Reclamar',
    recharge: "Recargar",
    upgrade: 'Actualizar',
    choseToken: "Choose Token",
    registerAsset: "Register an asset",
    deploy: 'Deploy'
  },
  wallet: {
    wallet: "Wallet",
    connectSteem: "Conectar Cartera Steem",
    connectHive: "Conectar Cartera HIVE",
    connectCosmos: "Conectar Cartera keplr",
    install: "Instalar",
    transfer: "Transferir",
    asset: "Activos",
    nomination: "Votos Delegadores ",
    contribution: "Crowdloan",
    balance: "balance",
    bond: "Vincular",
    unBond: "Desvincular",
    redeem: "Redimir",
    bindAmount: "Monto vinculado",
    inputAmount: "Introducir Monto",
    receiveAddress: "Dirección que recibe",
    transferAmount: "Monto a transferir",
    unbindAmount: "Monto no vinculado",
    intallKeplr: "Instale keplr por favor"
  },
  date: {
    year: " Año ",
    month: " Mes ",
    day: " Día ",
    hour: " Hora ",
    min: " Minuto ",
    sec: " Segundo ",
  },
  transaction: {
    broadcasting: "La transacción se está transmitiendo.",
    inBlock: "Transacción incluida en blockHash.",
    contributeOk: "¡Contribución exitosa!",
    withdrawOk: "¡Retiro Exitoso!",
    nominateOk: "!Nominar Éxito!",
    transactionOk: "!Transacción Exitosa!",
    depositOk: "¡Deposito Exitoso",
    withdrawOk: "¡Disminuir el éxito del depósito!",
    voteOk: 'Vote Exitoso',
    unvoteOk: "No votar Success",
    
  },
  stake: {
    stake: "Staking y Mineria",
    creaseDelegation: "Delegar",
    increaseDelegation: "Quitar Delegación",
    getSp: "Obtener SP",
    get: "Obtener",
    stake: "Depositar",
    unStake: "Quitar Deposito",
    staked: "Staked ",
    changeAccount: 'Por favor {type} cambia la cuenta',
    changeAccountHtml: `Por favor cambiar
    <span class="text-primary-0 font-bold">{type}</span>
    Account`,
    changeAddress: 'Por favor cambia {chainName} la dirección',
    changeAddressHtml: `Por favor cambiar
    <span class="text-primary-0 font-bold"> {chainName} </span>address`,
    accountMismatch1: `Tu {type} cuenta no puede unirse con la actual {chainName} dirección, por favor
    cambia {type} la cuenta en tu monedero primero.`,
    accountMismatch2: `Tu {chainName} dirección no se une con la actual {type} cuenta, por favor
    cambia {chainName} la dirección primero en tu monedero.`,
    accountMismatch3: `tu
    <span class="text-primary-0 font-bold">{type}</span>
    cuenta no se une con la actual
    <span class="text-primary-0 font-bold">{chainName}</span>
    dirección, por favor cambia
    <span class="text-primary-0 font-bold">{type}</span>
    la cuenta primero en tu billetera.`,
    accountMismatch4: ` Tu <span class="text-primary-0 font-bold"> {chainName} </span>
    dirección no se une con la actual
    <span class="text-primary-0 font-bold"> {type}
    </span>
    cuenta, por favor cambia
    <span class="text-primary-0 font-bold"> {chainName} </span>
    la dirección en tu cartera primero.`,
    bindAccountTip1: `-Tu cuenta vinculada {type} es:`,
    bindAddressTip1: 'Tu dirección vinculada es:'
  },
  tip: {
    approveSuccess: "Aprobado con éxito",
    loading: "Cargando, Por vavor espere",
    connectingPolkadot: "Conectar red polkadot",
    noAuction: "No hay subasta disponible",
    noProject: "No hay proyectos activos",
    noNominations: "No hay nodos migratorios",
    noContribuitons: "No hay contribuyentes registrados",
    noCommunities: "No hay comunidades registradas",
    noSearchedToken: "No se encuentra el Token",
    noCrowdloan: "No hay prestamos colectivos activos",
    noStakingProject: "No hay validadores",
    picTip: "El tamaño recomendado {size} ，no más de 2M, formatos soportados jpg、png、jpeg formatos",
    largeImg: 'Image size exceeds 2M',
    tokenSafeTip: "Su activo estará bloqueado en {symbol} en la paleta oficial del crowdloan, no ira a ninguna cuenta de Nutbox.",
    belowMinContribution: "La entrada es menor que la contribución mínima requerida de {min}",
    belowMinBond: "La entrada es menor que el monto mínimo sugerido del bono de 0.1",
    wrongNominatorAddress: "Dirección incorrecta del nominador",
    wrongTransferAddress: "Dirección de transferencia incorrecta",
    inputAddressType: "Por favor, agrega {relaychain} la Dirección",
    insufficientBalance: "Balance insuficiente",
    editTip: "Cargar información de la comunidad requiere el uso de la firma de su billetera, esta operación no le costará nada, tenga la seguridad de usar。",
    outOfCap: "fuera de la tapa",
    tips: "Tips",
    error: "Error！",
    success: "Éxito!",
    warning: "Advertencia！",
    noNeedWithdraw: "No es necesario retirar",
    comingSoon: "Próximamente, en breve, pronto",
    clipboard: "Portapapeles",
    copyAddress: "Dirección copiada:  {address}",
    signing: "Firmar",
    picUploadFail: "Las imágenes no se cargan, inténtalo de nuevo",
    outOfUsage: 'Upload so frequently, please try later.',
    uploadLogo: "Por favor cargue el logotipo",
    deloyTokenFail: "El token no se pudo implementar, inténtelo de nuevo",
    notContractAddress: "Proporcione la dirección del contrato del token de implementación",
    registryAssetFail: "Fallo de activo de registro",
    wrongStartBlockNum: "El BlockNum inicial debe ser mayor que el BlockNum actual",
    wrongStopBlockNum: "Parar el BlcokNum debe ser mayor que el BlockNum inicial",
    youHaveCreatedCommunity: "Has creado una comunidad",
    pleaseFillData: "Seleccione implementar políticas de activos y distribución",
    deployFactoryFail: "Error de fábrica al implementar, inténtelo de nuevo",
    deploySuccess: "Implementar con éxito",
    createPoolSuccess: "Crear éxito en la piscina",
    updatePoolSuccess: 'Actualizar las proporciones de la piscina con éxito',
    chargeSuccess: 'Carga exitosa',
    storeTokenAddress: "Confirme la información y almacene la dirección del token inmediatamente.",
    needName: "Ingrese el nombre de la comunidad",
    needWebsit: "Ingrese el sitio web de la comunidad",
    needDescription: "Ingrese la descripción de la comunidad",
    needIcon: "Cargue el logotipo de la comunidad",
    needTokenIcon: "Cargue el logotipo del token C",
    needPoster: "Cargue el póster de la comunidad",
    needTokenName: "Ingrese el nombre del token",
    needTokenSymbol: "Ingrese el símbolo del token",
    completeCommunityInfoSuccess: "Actualización de información de la comunidad con éxito",
    wrongSubstrateAddress: "Por favor ingresa la dirección {type} correcta",
    ratioError: "La proporción total de Staking Pool es 100",
    wrongApy: "Ingrese el APR correcto",
    wrongRewardNum: "Ingrese las recompensas correctas",
    deployCommunitySuccess: "Implementar con éxito!",
    communityNameLimit: "El nombre de la comunidad no puede exceder {count} los limites",
    descriptionLimit: "La descripción de la comunidad no puede exceder {count} los limites",
    needRightUrl: "Ingrese la URL correcta.",
    selectCowdloan: "Seleccione un proyecto de préstamo colectivo.",
    inputRightBlogTag: "Ingrese una etiqueta de comunidad de Steem correcta. por ejemplo: hive-12345.",
    withdrawSuccess: "retiro Exitoso!",
    harvestSuccess: "Cosecha Exitosa!",
    deploying: "Implementando, puede tomar algún tiempo, espere un momento.",
    stopPoolOk: "La piscina ha sido detenida",
    removePoolOk: "Se ha eliminado la piscina",
    tryWithdrawOk: "Éxito al redimir.",
    stopPoolTips: "Necesita establecer la relación de grupo en 0 antes de detener el grupo.",
    completeCommunityProposalConfigSuccess: "Éxito en la configuración de la propuesta de la comunidad",
    completeProposalSuccess: "Propuesta exitosa",
    completeVoteSuccess: "Éxito de la votación",
    needGameName: "Por favor ingrese el nombre del juego",
    gameNameLimit: "El nombre del juego no puede exceder{count}el limite de palabras",
    needGameType: "Por favor elige el tipo de juego",
    completeGameSuccess: "Éxito del juego",
    deployTokenTip: 'Atención: este es el monto de predistribución acuñado a su propia cuenta, no el suministro total. por favor ten cuidado.',
    attention: "Atención!",
    markdownTip: "Puedes usar el formato de descuento.",
    stopPoolAttention: 'Una vez que cierra la piscina, no se puede volver a abrir. Utilice esta función con precaución.',
    stakeTokenLogoTip: "Este logotipo está configurado para el token de apuesta {token} que seleccionaste.",
    updateSocialSuccess: 'Subido con éxito',
    startHeightOut: 'El primer bloque de inicio debe ser más grande que el bloque actual.',
    wrongSocialInfo: "Por favor, rellene a la derecha {type} el link",
    noPowerupNut: "No he encendido NUT",
    needAscii: 'Solo puede ingresar caracteres simples.',
    npRedeemProcess: 'Sin proceso de canje.',
    powerDownSuccess: 'Apague el éxito',
    redeemSuccess: "Canjeo exitoso",
    mintableTip1: 'Ha configurado c-Token mintable cuando crea la comunidad.',
    mintableTip2: "Ahora debe otorgar el rol de menta al contrato comunitario, de lo contrario no puede usar las funciones de esta comunidad.",
    delegateTip1: "Estas usando {chain} la cuenta: {account} delegado.",
    rechargeTip1: "Recargar más tarde",
    feeTip: 'Tarifa de operación: ${fee} NUT'
  },
  nps: {
    nps: "Governanza",
    npsTemp: "Muestre la gobernanza de Peanut, por ejemplo. La gobernanza de Walnut está en desarrollo.",
    all: "Todo",
    pass: "Aprovado",
    pending: "Pendiente",
    unpass: "Rechazado",
    rolling: "Votación en curso",
    mine: "My Publicación",
    title: "Titulo",
    titleInputTip: "Por favor agrega el titulo",
    startAndEndTime: "inicio & tiempo final",
    startTime: "Tiempo de inicio",
    endTime: "Tiempo final",
    details: "Detalles",
    detailsInputTip: "Por favor agrega los detalles de la propuesta.",
    proposalConfigLabel: "Configurar propuesta",
    voteProposal: "Votar propuesta",
    createProposal: "Crear Propuesta",
    noProposals: "No hay propuestas",
    proposalTitle: "Titulo",
    proposalTitleInput: "Please ingresa el titulo de la propuesta",
    proposalStart: "Tiempo de inicio",
    proposalEnd: "Tiempo final",
    proposalFirst_Block: "Iniciar Bloque",
    proposalEnd_Block: "Parar Bloque",
    proposalBody: "Detalle",
    proposalBodyPreview: "Preview",
    proposalBodyInput: "Por favor ingresa los detalles",
    proposalNetwork: "Red",
    proposalNetworkInput: "Por favor selecciona la red",
    proposalSymbol: "Simbolo",
    proposalSymbolInput: "Por favor selecciona el simbolo",
    proposalStrategies: "Estrategia",
    proposalThreshold: "Límite",
    proposalThresholdInput: "Ingrese el umbral",
    proposalPassThreshold: "Umbral de paso",
    proposalPassThresholdInput: "Ingrese el umbral de aprobación",
    proposalValidation: "Validation",
    proposalValidationInput: "Please input validation",
    proposalAgreeBtn: "Aceptar",
    proposalDisagreeBtn: "Discrepar",
    propsalSureVote: "Estas seguro?",
    propsalVoteRight: "Su derecho de voto",
    propsalVoteRemind: "Esta seguro {0}?",
    propsalVoteStatusWaitStart: "Esperar",
    propsalVoteStatusDoing: "Votar",
    propsalVoteStatusEnd: "Terminar",
    proposalInfo: "Información de la propuesta",
    proposalVoteNum: "número de voto",
    proposalVoteResult: "Resultado del voto",
    proposalStatus: "Estatus del voto",
    editProposalConfigBtn: "Configuración de Propuesta",
    editStrategy: "Editar Estrategia",
    validationWarning: {
      basic: {
        minScore: "Tu necesitas un mínimo de {0} {1} para enviar una propuesta.",
      },
      customValidation: "Debe aprobar la validación de la propuesta para enviar una propuesta.",
    },
    remark: "Introducción",
    downloadReport: "Descargar Informe",
    completeContent: "Por favor llene todos los espacios en blanco.",
    endLtStart: 'El número de bloque final debe ser mayor que el bloque de inicio',
    blockExceed: 'El número de bloque final debe ser mayor que el número de bloque actual'
  },
  error: {
    error: "Error",
    depositFail: "Deposito Fallido",
    withdrawFail: "Retiro Fallido",
    changeDepsitFail: "Cambio de depósito fallido",
    delegateFail: "Delegado fallido",
    changeDelegateFail: "Cambio de delegación fallida",
    haveCreatedCommunity: "Ya creaste una comunidad. Por favor actualice para probar.",
    approveFail: "Aprobar fallar",
    accountChanged: "Discrepancia en la dirección de Steem y Tron, confirme si la cuenta de la billetera está cambiada",
    delegateerror: "Error en Delegación！",
    tryrefreshpage: "Error, actualice la página y vuelva a intentarlo",
    needkeychainandtronlink: "Necesita instalar Steem Keychain y TronLink",
    needkeychain: "Instale el monedero Steem",
    needtronlink: "Instale TronLink",
    insufficientEnerge: "No tiene suficiente energia",
    transferFail: "Transferencia Fallida",
    unlockKeychain: "Por favor desbloquea Steem Keychain",
    unlockWallet: "Por favor desbloquea tronlink",
    steemLoginFail: "Steem ingreso fallido",
    hiveLoginFail: "Hive ingreso fallido",
    illegalTronAddress: "Dirección tron invalida",
    inputError: "Ingrese un valor incorrecto",
    inputOverflow: "Desbordamiento de entrada",
    inputOutOfRange: "Por favor ingrese el valor entre {lowerPnutAmount}-{upperPnutAmount}",
    inputLinkIllegal: "Por favor ingrese el enlace correcto",
    notEnoughFee: "Tarifa insuficiente",
    insufficentPnut: "PNUT insuficiente",
    insufficentErc20: "Insuficiente {symbol}",
    hasVoted: "Tu publicación ha sido votada por nutbox.mine",
    pleaseRetry: "Inténtalo de nuevo",
    signatureFailed: "Error de firma, actualice la página y vuelva a intentarlo",
    serveError: "Error del Servidor",
    networkError: "Error de red",
    noStakingFactory: "Aún no has creado una comunidad",
    blockChainError: "Error de invocación de contrato",
    cancelSigning: "Cancelar firma",
    unknow: "Error desconocido",
    notConnectWallet: "Monedero no conectado",
    unlockWallet: "Por favor, desbloquee Wallet",
    wrongChainId: "ID de cadena incorrecto",
    wrongETHAddress: "Dirección ETH incorrecta",
    wrongInputDevRatio: "Por favor ingrese un número entre 0-100",
    notTokenContract: "La dirección que ingresa no es una dirección de contrato BEP20.",
    transactionFail: "¡Falló la transacción!",
    assetHasRegisterd: "El activo ha sido registrado por alguien.",
    steemAccountEmpty: "Falta la cuenta de Steem",
    steemAccountActiveKeyEmpty: "Falta la cuenta de Steem o la clave activa",
    hiveAccountEmpty: "Falta la cuenta de Hive",
    hiveAccountActiveKeyEmpty: "Falta la cuenta de Hive o la clave activa",
    tokenDeploying: "Hay un token en la implementación, espere o actualice la página para intentarlo.",
    wrongConfirmInfo: "Fallo al confirmar la información"
  },
  // navigator
  router: {
    information: "Información",
    profile: "Perfil",
    asset: "Activos",
    pool: "Pool",
    social: "Información Social",
    governance: "Governanza",
    staking: "Staking",
    blog: "Blog",
    member: "Miembro",
    home: 'Inicio',
    farming: 'Farming',
    nftStake: 'NFT Stake',
    nftStaking: 'NFT Staking',
    nutPower: 'NUT Power'
  },
  // placeHolder tip
  placeHolder: {
    inputName: "Ingrese el nombre de la comunidad",
    inputLink: "Ingrese el enlace del sitio web de la comunidad",
    inputDesc: "Ingrese la descripción de la comunidad ",
    inputApy: "Por favor ingrese el APR",
    inputStartBlock: "Ingrese el bloque de inicio",
    inputStopBlock: "Ingrese bloque de parada",
    inputMintAmount: "Por favor ingrese el número de menta",
    inputDevRatio: "Ingrese la proporción de fondos de DAO",
    remarkInput: "Por favor ingrese introducir",
    confirmInfo: 'Por favor, ingresa "{info}" para confirmar.',
    inputPoolName: 'Ingrese el nombre del grupo.',
    inputDelegatee: 'Ingrese la cuenta de la delegación',
    inputDevAddress: 'Ingrese la nueva dirección del fondo DAO'
  },
  community: {
    community: "Comunidad",
    social: "Social",
    communityList: "Lista de la comunidad",
    communityTokenList: "Lista de C-Token",
    communityName: "Nombre",
    communityLink: "Link oficial",
    communityDesc: "Descripción",
    communityLogo: "Logo",
    cTokenLogo: "Logo del C-Token",
    communityThemeColor: "Color del tema",
    communityPoster: "Póster",
    communityBalance: "Saldo comunitario",
    communityToken: "Ficha comunitaria",
    communityAsset: 'Activo comunitario',
    fundAddress: "Dirección del fondo",
    fundRatio: "Relación de fondos",
    createCommunity: 'Crear comunidad',
    manageCommunity: 'Administrar comunidad',
    communityDashboard: "Panel de la comunidad",
    communtiyDesc: "Es fácil llevar DeFi, redes sociales y gobernanza a la comunidad.",
    totalDeposit: 'Cantidad total',
    currentBlock: 'Bloque actual',
    joinedCommunity: 'Comunidades unidas',
    noJoinedCommunity: 'Sin comunidades unidas',
    noJoinedPool: "No hay pools activos",
    exploreCommunity: 'Explorar comunidades',
    retainedRevenue: 'Ingresos retenidos',
    insufficientBalance: "Saldo comunitario insuficiente",
    member: 'Miembro',
    daoFundInfo: 'Información del Fondo Dao',
    daoTreasuryInfo: 'Información del tesoro de Dao',
    choseToken: `Haz elegido <span class="text-primary-0 font20"> {symbol} </span> como token comunitario.`,
    choseTokenAsCtoken: 'Elija un token como cToken',
    needTokenToCreate: `Antes de la distribución, necesita un depósito suficiente
    <span class="text-primary-0 font20"> {symbol} </span>
    a la comunidad.`,
    chargeTip1: "Puedes hacerlo ahora o más tarde en la página de administración de la comunidad.",
    noCommunity: "Aún no tienes comunidad",
    choseAsset: 'Chose an asset',
    deployAsset: 'Deploy community asset',
    completeInfo: 'Complete info',

    startBlock: "Bloque de inicio",
    stopBlock: "Detener bloque",
    mintAmount: "Cantidad de token acuñado",

    opened: 'Abierto',
    closed: 'Cerrado'
  },
  pool: {
    updatePoolRatios: "Configurar Pool",
    addPool: "Añadir Pool",
    chosePoolType: 'Elige tipo de Pool',
    pools: 'Pools',
    poolName: 'Nombre del Pool',
    noPools: 'Ningún Pool ha sido creado',
    userCount: 'Conteo de usuarios',
    totalDeposit: 'Depósito total',
    totalStaked: 'Total en Staked',
    totalDelegated: 'Total Delegado',
    stakedPools: 'Staked en Pools',
    tvl: 'TVL',
    closePool: "Cerrar",
    ratio: 'Relación',
    opened: 'Activa',
    closed: 'Cerrado',
    activePools: 'Pools Activos',
    inactivePools: 'Pools Inactivos',
    creatPool: 'Crear grupo de participación en {chainName}',
    createDelegatePool: 'Crear {delegateType} Pool delegado',
    createNftPool: 'Crear Stake de NFT (ERC1155) pool en {chainName}',
    yourDelegatee: 'Tu {delegateType} cuenta de delegación',
    ratioTip: 'Relación de reparto de utilidades (Sum of ratios should be 100%)'
  },
  np: {
    nutPower: 'NUT Power（NP）',
    npDesc: "Nutbox Voting Power (NUT Power para abreviar) es el poder de gobierno de Nutbox DAO. La recompensa de gobierno de Nutbox de un usuario se cobra por la actividad de gobierno del usuario.",
    claimNut: 'Reclamar NUT',
    claimNp: 'Reclamar NP',
    claimAvailable: 'Reclamar lo disponible：',
    availabeClaimNut: '{amount} Nut esta disponible para reclamar.',
    addNpPool: "Añade NP al voto del pool",
    npTip1: "Para agregar un grupo de votos de NUT Power, debe elegir un grupo de participación existente. Los usuarios pueden ganar NUT y c-token del grupo de votos de NP.",
    npTip2: "* NUT se emite por contrato de Nutbox, c-token es de la comisión del grupo de participación.",
    npTip3: "NP disponible: NP podría usarse para votar o desbloquear.",
    npTip4: 'NP votado: NP votaste en grupos de Walnut.',
    npTip5: 'Desbloqueo de NP: NP que apagó, en el proceso de desbloqueo.',
    voteFor: 'Votar por',
    claimbleNut: 'NUT para reclamar',
    claimedNut: 'NUT reclamado:',
    totalNp: 'Total NP',
    totalNpTip: 'Total NP: Todo el NP que posees.',
    powerDown: 'Power down',
    powerDownTip: 'Power down NP a NUT',
    powerDownTip2: '{selectRatio} cada | semana {selectRatio} NP a 1 NUT',
    powerUpTip1: 'Actualice el período de desbloqueo de NP desde {srcPeriod} semana a {distPeriod} semana.',
    powerUpTip2: 'Power up cada 1 NUT a {distPeriod} NP',
    powerUpTip3: 'Según el período de desbloqueo que elija, puede encender 1 NUT a 1-64 NP.',
    powerUpTip4: `Solías pasar {nut} NUT a {np} NP. Ahora puedes actualizarlos para obtener más NP. Simplemente elija uno de los bloques a continuación. Esté atento, el período de desbloqueo crecerá simultáneamente.`,
    powerUpTip5: '1 NUT a {ratio} NP',
    powerUpTip6: '{period} NP a {ratio} NP',
    powerUpTip7: 'Desbloquear periodos: {unlockTime} semanas',
    nutStaked: "NUT Staked:",
    npAvailable: 'NP Disponible:',
    npVoted: 'NP Votado:',
    unlockPeriod: 'Desbloquear periodo:',
    unlockingNp: 'Desbloquear NP',
    unlockingPeriod: 'Desbloquear periodo',
    unlockInWeeks: 'Desbloquear en {period} semanas：',
    covertTip: '{npAmount} NP a {nutAmount} NUT',
    daysLeft: ' días restantes',
    hoursLeft: ' horas restnates',
    minutesLeft: ' minutos restantes',
    secondsLeft: ' segundos restantes',
    upgradeTip1: `Cuando desee volver a convertir su NP a NUT, debe realizar la operación de apagado, lo que tomará
    <strong class="text-primary-1"> {distPeriod} semanas</strong>
    para devolver tu NUT gradualmente.`
  },
  gauge: {
    voterCount: 'Votantes',
    totalVoted: 'Votos totales',
    vote: "Votar",
    unvote: "Eliminar voto"
  },
  asset: {
    tokenName: "Nombre",
    tokenSymbol: "Simbolo",
    tokenAddress: "Dirección del token",
    tokenId: "Token ID",
    wrongTokenId: "Token ID debe integrar un número.",
    price: "Precio",
    totalSupply: 'Suministro',
    cap: 'Mkt Cap',
    distributionAmount: "Cantidad de recompensa",
    notErc1155: "El token no es un token ERC1155n.",
    wrongAddress: 'Wrong Address',
    choseCToken: 'Choose a token as cToken',
    setDistr: 'Setting your asset distribution',
    totalDistr: 'Total distribution by current policy',
    currentBlock: 'Current Block height',
    tip1: 'The token you choose is mintable.',
    tip2: 'For your convenience, you can grant the mint authority to community contract. Then you need not reserve your token into community contract in the future times and times.',
    tip3: 'It should be noted that once you set this option, it cannot be changed in the future.',
    tip4: "If you don't understand the mechanism, just keep the box unchecked.",
    promiseGrant: 'Yes, I will grant mint role to community contract later.',
    tip5: 'Your community contract is deployed successfully!',
    tip6: 'Please confirm to grant the mint role to the contract.'
  },
  treasury: {
    treasuryAddress: "Dirección de Tesorería",
    daoTreasury: "DAO Tesorería",
    treasuryAsset: "Tesorería de Activos",
    noCreatedTip: "Ahora puedes crear tu propia comunidad de Tesorería.",
    createNote: "NOTA: una vez que crea la tesorería, cualquiera puede canjear activos de la tesorería de DAO. Use cToken como parte de los activos de tesorería.."
  },
  opHistory: {
    deposit: "Depositar {amount} {symbol} a {pool}",
    depositWithName: " depositar {amount} {symbol} a {pool}",
    add: "Añadir {amount} {symbol} a {delegatee} de {pool}",
    addWithName: " añadir {amount} {symbol} a {delegatee} de {pool}",
    withdraw: "Retirar {amount} {symbol} de {pool}",
    withdrawWithName: " retirar {amount} {symbol} de {pool}",
    sub: "Menos {amount} {symbol} a {delegatee} de {pool}",
    subWithName: "{accoun} menos {amount} {symbol} a {delegatee} de {pool}",
    vote: "Votar {amount} {symbol} a {pool}",
    voteWithName: " votae {amount} {symbol} a {pool}",
    unvote: "Eliminar voto {amount} {symbol} a {pool}",
    unvoteWithName: " eliminar voto {amount} {symbol} a {pool}",
    harvestGauge: "Cosechar {amount} {symbol} de {pool}",
    harvestGaugeWithName: " cosechar {amount} {symbol} de {pool}",
    createGauge: 'Crear nuevo pool de voto para  {pool}',
    createGaugeWithName: ' Crear pool de voto de {pool}',
    withdrawGauge: "Cosechar {amount} NUT de calibre",
    withdrawGaugeWithName: " Cosechar {amount} NUT de calibre",
    harvest: "Cosechar {amount} {symbol} de {pool}",
    harvestWithName: " cosechar {amount} {symbol} de {pool}",
    harvestAll: "Cosechar todo {symbol}",
    harvestAllWithName: " cosechar todo {symbol}",
    redeemTreasury: "Canjear a la tesorería por quema {amount} {symbol}",
    redeemTreasuryWithName: " Canjear a la tesorería por quema {amount} {symbol}",
    create: "crear esta comunidad ",
    createWithName: " crear esta comunidad",
    setFee: "Cambiar la proporción de fondos de DAO a {amount}%",
    setFeeWithName: " cambiar la relación de fondos DAO a {amount}%",
    addPool: 'Crear una nueva pool: {pool}',
    addPoolWithName: ' Crear una nueva pool: {pool}',
    closePool: "Cerrar pool: {pool}",
    closePoolWithName: " Cerrar pool: {pool}",
    setRatio: "Restablecer las proporciones de la piscina",
    setRatioWithName: " restablecer las proporciones del pool",
    setDaofund: "Restablecer la dirección del fondo DAO a : {address}",
    setDaofundWithName: " Restablecer la dirección del fondo DAO a: {address}",
    withdrawRevenue: "Retirar ingresos a {address}",
    withdrawRevenueWithName: " ingresos a {address}",
    createTreasury: "Crear tesorería: {address}",
    createTreasuryWithName: " crear tesorería: {address}",
    title: {
      DEPOSIT: "Depositar",
      WITHDRAW: 'Retirar',
      VOTE: "Votar al pool",
      UNVOTE: "Retirar voto del pool",
      WITHDRAWGAUGENUT: "Cosecha NUT por voto NP",
      ADMINCREATENEWGAUGE: "Crear voto de encuesta",
      WITHDRAWGAUGECTOKEN: "Cosecha C-Token por voto NP",
      ADMINWITHDRAWGAUGENUT: "Cosecha NUT por voto NP",
      HARVEST: "Cosechar",
      HARVESTALL: "Cosechar TODO",
      REDEEMFROMTREASURY: "Canjear del Tesoro",
      ADMINCREATE: "Crear comunidad",
      ADMINSETFEE: "Establecer proporción de fondos DAO",
      ADMINADDPOOL: 'Crear nuevo pool',
      ADMINCLOSEPOOL: "Cerrar Pool",
      ADMINSETRATIO: "Restablecer proporciones de grupo",
      ADMINSETDAOFUND: "Restablecer fondo DAO",
      ADMINWITHDRAWNREVENUE: "Retirar ingresos",
      ADMINCREATETREASURY: "Crear tesorería"
    }
  }
};