import { render, staticRenderFns } from "./CommunityAsset.vue?vue&type=template&id=32b1f998&scoped=true&"
import script from "./CommunityAsset.vue?vue&type=script&lang=js&"
export * from "./CommunityAsset.vue?vue&type=script&lang=js&"
import style0 from "./CommunityAsset.vue?vue&type=style&index=0&id=32b1f998&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b1f998",
  null
  
)

export default component.exports