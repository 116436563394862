module.exports = {
    commen: {
        steemAccoutPlaceHolder: "Steem 계정",
        hiveAccountPlaceHolder: "Hive 계정",
        loginByKeychain: "키체인으로 로그인",
        loging: "로그인 중...",
        crowdstaking: "스테이킹",
        optional: "(선택사항)",
        connectMetamask: 'Metamask에 연결',
        delegateFee: '임대 수수료',
        apy: "APR",
        balance: "잔액",
        max: "최대",
        complete: "완료",
        now: "지금",
        poster: "포스터",
        more: '더욱',
        detail:'디테일',
        activities: '활동들',
        administor: '관리자',
        joinDate: '가입 날짜',
        noData: '데이터 없음',
        pageNotFound: "페이지를 찾을 수 없습니다!",
        backHome: "홈으로 돌아가기",
        amount: "양",
        earned: '획득한',
        voted: "투표된",
        staked: "스테이킹된",
        delegated: "임대된",
        totalStake: '총 스테이킹',
        voters: '투표자들',
        stakers: '스테이커들',
        summary: '요약',
        weeks: '주',
        or: '또는',
        
        whitePaper: "백서",
        nps: "Nutbox Proposal System",
        contract: "계약",
        social: "Social 정보",
        docs: "문서들",
        discord: "디스코드",
        blog: "블로그",
        contractAddr: "Contract 주소",
        dashboard: "게시판",
        auditReport: "회계 보고서",
        language: "언어",
        zh: "중국어",
        en: "영어",
        kr: "한국어",
        my: "말레이시아어",
        tr: "터키어",
        es: "스페인어",
        jp: "중국어",
        goToHome: "홈페이지로 돌아가기",
        home: '홈',
    },
    desc: {
        slogan: '스테이킹 이코노미를 몇분만에 만들어보세요',
        featuredCommunity: '현재 커뮤니티들',
        aboutNutbox: 'Nutbox 대해서',
        totalValue: '총 가치',
        disStrategy: '분배 전략',
        adminOnly: '관리자외 제한',
        youWillReceive: '당신은 받을 것입니다: ',
        iso: 'ISO: Initial Staking Offering',
        isoDetail: 'Stake PoS 토큰을 스테이킹하고 커뮤니티 토큰을 발행하세요. 높은 APR, 낮은 위험.',
        erc1155Desc: 'ERC1155 토큰을 스테이킹하세요.',
        nutPower: 'Nut power는 Nutbox DAO의 거버넌스 권리입니다. 이것은 NUT를 스테이킹하면서 만들어지고 더 오래 잠궈놓을수록 더 많은 POWER가 생깁니다. 또한 새롭게 NUT를 분배받을 수 있는 핵심 요소이기도 합니다.',
        farming: '벌기 위해 토큰을 스테이킹 하세요.',
    },
    operation: {
        withdraw: "출금",
        harvest: "수확",
        approveContract: "Contract 승인",
        approveCommunity: '커뮤니티 승인',
        approvePool: 'Pool 승인',
        connectMetamask: "Metamask 연결",
        change: '변화',
        search: "검색",
        userlogin: "로그인",
        uploadLogo: "로고 업로드",
        uploadPoster: "포스터 업로드",
        sign: "로그인 후 업로드",
        hide: '숨김',
        cancel: "취소",
        commit: "주문",
        complete: "완료",
        confirm: "확인",
        create: "만들기",
        add: "추가",
        update: "업데이트",
        delegatecharge: "임대 충전하기",
        login: "로그인",
        logout: "로그아웃",
        input: "입력",
        ok: 'OK',
        copy: "복사",
        edit: "편집",
        charge: '청하기',
        redeem: "회수하기",
        enter: 'Enter',
        approve: '승인',
        grant: '허가',
        manageCommunity: "커뮤니티 관리하기",
        exploreCommunity:'커뮤니티 탐구하기',
        save: '저장',
        grantMintRole: '민팅 역할 부여',
        configurePoolRatio: '임대 Pool 설정',
        addDelegatePool: '임대 Pool 추가하기',
        addPool: 'Pool 추가하기',
        claim: 'Claim',
        recharge: "재충전",
        upgrade: '업그레이드'
    },
    wallet: {
        wallet: "지갑",
        connectSteem: "Steem Wallet 연결",
        connectHive: "Hive Wallet 연결",
        connectCosmos: "Keplr Wallet 연결",
        install: "설치하기",
        transfer: "전송하기",
        asset: "자산",
        nomination: "위임자들 투표하기",
        contribution: "Crowdloan",
        balance: "잔액",
        bond: "묶기",
        unBond: "풀기",
        redeem: "회수",
        bindAmount: "묶인 양",
        inputAmount: "입력 양",
        receiveAddress: "수신인 주소",
        transferAmount: "전송 양",
        unbindAmount: "푸는 양",
        intallKeplr: "keplr를 설치해 주세요"
    },
    date: {
        year: " 년 ",
        month: " 월 ",
        day: " 일 ",
        hour: " 시간 ",
        min: " 분 ",
        sec: " 초 ",
    },
    transaction: {
        broadcasting: "거래 알림 중.",
        inBlock: "blockHash에서 거래 포함.",
        contributeOk: "기여 성공!",
        withdrawOk: "출금 성공!",
        nominateOk: "지명 성공!",
        transactionOk: "거래 성공!",
        depositOk: "입금 성공",
        withdrawOk: "입금 성공 감소!",
        voteOk: '투표 성공',
        unvoteOk: "투표해지 성공",
    },
    stake:{
        stake: "스테이킹 마이닝",
        creaseDelegation: "임대",
        increaseDelegation: "임대 해제",
        getSp: "SP 얻기",
        get: "얻기",
        stake: "입금",
        unStake: "입금풀기",
        staked: "스테이킹된 ",
        changeAccount: '계정을 {type} 변경해주세요',
        changeAccountHtml: `계정을
        <span class="text-primary-0 font-bold">{type}</span>
        변경해주세요`,
        changeAddress: '주소를 {chainName} 변경해주세요',
        changeAddressHtml: `주소를
        <span class="text-primary-0 font-bold"> {chainName} </span>변경해주세요`,
        accountMismatch1: `여러분의 {type} 계정은 현재 주소에 {chainName} 묶여있지 않습니다, 여러분
         지갑의 계정을 먼저 {type} 변경해주세요.`,
        accountMismatch2: `여러분의 {chainName} 계정은 현재 주소에 {type} 묶여있지 않습니다, 여러분
        지갑의 계정을 먼저 {chainName} 변경해주세요.`,
        accountMismatch3: `여러분의
        <span class="text-primary-0 font-bold">{type}</span>
        계정은 현재 주소에 묶여있지 
        <span class="text-primary-0 font-bold">{chainName}</span>
        않습니다, 지갑안에 있는 계정을 먼저
        <span class="text-primary-0 font-bold">{type}</span>
        변경해주세요.`,
        accountMismatch4: ` 여러분의 <span class="text-primary-0 font-bold"> {chainName} </span>
      계정은 현재 주소에 묶여있지 
        <span class="text-primary-0 font-bold"> {type}
        </span>
       않습니다, 지갑안에 있는 계정을 먼저
        <span class="text-primary-0 font-bold"> {chainName} </span>
        변경해주세요.`,
        bindAccountTip1: `여러분의 묶여있는 {type} 계좌는:`,
        bindAddressTip1: '여러분의 묶여있는 주소는:'
     },
     tip: {
        approveSuccess: "승인 성공",
        loading: "로딩 중, 기다려 주세요",
        connectingPolkadot: "Polkadot 네트워크에 연결해 주세요",
        noAuction: "진행 중인 Auction 없음 ",
        // noProject: "진행 중인 프로젝트 없음",
        noNominations: "지명자 노드 없음",
        noContribuitons: "기여 기록 없음",
        noCommunities: "등록된 커뮤니티 없음 ",
        noSearchedToken: "검색된 토큰 없음",
        noCrowdloan: "진행 중인 Crowdloan 없음",
        noStakingProject: "검증자 없음",
        picTip: "추천 사이즈는 {size} ，200만보다 작고, jpg、png、jpeg 포맷을 지원합니다",
        tokenSafeTip: "여러분의 자산은 공식적인 크라우드론에 {symbol} 직접 잠겨있지, 넛박스의 계정에 잠겨있는 것이 아닙니다.",
        belowMinContribution: "인풋은 최소 요구된 기여보다 적습니다 {min}",
        belowMinBond: "인풋은 최소 제안된 0.1의 채권 양보다 적습니다",
        wrongNominatorAddress: "잘못된 지명자 주소",
        wrongTransferAddress: "잘못된 전송 주소",
        inputAddressType: "주소를  {relaychain} 입력해 주세요",
        insufficientBalance: "부족한 잔액",
        editTip: "커뮤니티 정보 업로딩은 여러분의 지갑 사인 사용을 요구하며 이 작업은 비용이 들지 않습니다, 안심하고 사용해 주세요。",
        largeImg: 'Image size exceeds 2M',
        outOfCap: "상한선 없음",
        tips: "팁들",
        error: "에러！",
        success: "성공!",
        warning: "경고！",
        noNeedWithdraw: "출금 필요없음",
        comingSoon: "얼마 남지 않았습니다",
        clipboard: "클립보드",
        copyAddress: "복사된 주소:  {address}",
        signing: "사인",
        picUploadFail: "사진 업로드 실패, 다시 시도해 주세요",
        outOfUsage: 'Upload so frequently, please try later.',
        uploadLogo: "로고를 업로드해 주세요",
        deloyTokenFail: "토큰 배치 실패, 다시 시도해 주세요",
        notContractAddress: "Deploy 토큰 Contract 주소를 제공해 주세요",
        // registryAssetFail: "자산등록 실패",
        wrongStartBlockNum: "Start BlockNum은 현재보다 더 커야한다",
        wrongStopBlockNum: "Stop BlcokNum은 Start BlockNum보다 커야한다",
        youHaveCreatedCommunity: "커뮤니티를 만들었습니다",
        pleaseFillData: "자산과 분배 방침을 선택해 주세요",
        deployFactoryFail: "공장 배치 실패, 다시 시도해 주세요",
        deploySuccess: "배치 성공",
        createPoolSuccess: "풀 만들기 성공",
        updatePoolSuccess: '풀 비율 만들기 성공',
        chargeSuccess: '충전 성공',
        // storeTokenAddress: "정보를 확인하고 토큰 주소를 즉시 저장해 주세요",
        needName: "커뮤니티 이름을 입력해 주세요",
        needWebsit: "커뮤니티 웹사이트를 입력해 주세요",
        needDescription: "커뮤니티 설명을 입력해 주세요",
        needIcon: "커뮤니티 로고를 업로드해 주세요",
        needTokenIcon: "로고를 업로드해 주세요",
        needPoster: "커뮤니티 포스터를 업로드해 주세요",
        needTokenName: "토큰 이름을 입력해 주세요",
        needTokenSymbol: "토큰 심볼을 입력해 주세요",
        completeCommunityInfoSuccess: "커뮤니티 정보가 성공적으로 업데이트 됩니다",
        wrongSubstrateAddress: "올바른 주소를 {type} 입력해 주세요",
        ratioError: "100스테이킹 풀의 총 비율은",
        // wrongApy: "올바른 APR을 입력해 주세요",
        wrongRewardNum: "올바른 보상을 입력해 주세요",
        deployCommunitySuccess: "배치 성공!",
        communityNameLimit: "커뮤니티 이름은 단어를 {count} 초과할 수 없습니다",
        descriptionLimit: "커뮤니티 설명은 단어들을 {count} 초과할 수 없습니다",
        needRightUrl: "올바른 url을 입력해 주세요.",
        // selectCowdloan: "크라우드론 프로젝트를 선택해 주세요.",
        inputRightBlogTag: "올바른 스팀 커뮤니티 태그를 입력해주세요. 예:hive-12345.",
        withdrawSuccess: "출금 성공!",
        harvestSuccess: "수확 성공!",
        deploying: "배치하기, 시간이 좀 걸릴 수 있습니다, 잠시 기다려 주세요.",
        stopPoolOk: "풀이 멈췄습니다",
        removePoolOk: "풀이 삭제되었습니다",
        tryWithdrawOk: "입금 성공.",
        stopPoolTips: "풀이 멈추기 전에 비율 0까지 설정 필요.",
        completeCommunityProposalConfigSuccess: "커뮤니티 제안 설정 성공",
        completeProposalSuccess: "제안 성공",
        completeVoteSuccess: "투표 성공",
        // needGameName: "게임 이름을 입력해 주세요",
        // gameNameLimit: "커뮤니티 이름은 단어들을{count}초과할 수 없습니다",
        // needGameType: "게임 유형을 선택해 주세요",
        // completeGameSuccess: "게임 성공",
        deployTokenTip: '주목: 이것은 여러분의 계좌로의 선 분배량이며 총 공급양이 아닙니다. 주의 하세요.',
        attention: "주목!",
        markdownTip: "마크다운 형식을 사용할 수 있습니다.",
        stopPoolAttention: '풀이  한 번 닫히면 다시 열리지 않습니다. 이 기능에 주의해 주세요!',
        stakeTokenLogoTip: "이 로고는 여러분이 선택한 스테이크 토큰으로 {token} 설정됩니다.",
        updateSocialSuccess: '업데이트 성공!',
        startHeightOut: '첫 시작 블록은 현재 블록보다 더 커야 됩니다.',
        wrongSocialInfo: "올바른 링크에 {type} 채워주세요!",
        noPowerupNut: "NUT을 파워업 한적이 없습니다",
        needAscii: '간단한 캐릭만 입력할 수 있습니다.',
        npRedeemProcess: '입금 프로세스 없음',
        powerDownSuccess: '파워 다운 성공',
        redeemSuccess: "입금 성공",
        mintableTip1: '커뮤니티를 만들 때 c-Token을 채굴가능하게 설정했습니다.',
        mintableTip2: "이제 여러분은 민팅 롤을 커뮤니티 계약에 부여해야하며, 그렇지 않으면 커뮤니티의 기능을 사용할 수 없습니다.",
        delegateTip1: "임대하기 위해 {chain} 계좌를: {account} 사용하고 있습니다.",
        rechargeTip1: "나중에 재충전",
        feeTip: '작업 수수료: ${fee} NUT'
      },
      nps: {
        nps: "거버넌스",
        // npsTemp: "Show Peanut governance for example. Walnut governance is under development.",
        all: "모두",
        pass: "통과",
        pending: "대기 중",
        unpass: "거절",
        rolling: "투표",
        mine: "나의 생산",
        title: "제목",
        titleInputTip: "제목을 입력해 주세요",
        startAndEndTime: "시작 & 끝 시간",
        startTime: "시작 시간",
        endTime: "끝 시간",
        details: "상세내역",
        detailsInputTip: "제안 상세내역을 입력해 주세요.",
        proposalConfigLabel: "제안 설정",
        voteProposal: "투표 제안",
        createProposal: "제안 만들기",
        noProposals: "제안 없음",
        proposalTitle: "제목",
        proposalTitleInput: "제안 제목을 입력해 주세요",
        proposalStart: "시작 시간",
        proposalEnd: "끝 시간",
        proposalFirst_Block: "시작 블록",
        proposalEnd_Block: "멈춘 블록",
        proposalBody: "상세",
        proposalBodyPreview: "미리보기",
        proposalBodyInput: "상세내역을 입력해 주세요",
        proposalNetwork: "네트워크",
        proposalNetworkInput: "네트워크를 선택해 주세요",
        proposalSymbol: "심볼",
        proposalSymbolInput: "심볼을 입력해 주세요",
        proposalStrategies: "전략",
        proposalThreshold: "한계점",
        proposalThresholdInput: "한계점을 입력해 주세요",
        proposalPassThreshold: "한계점 통과",
        proposalPassThresholdInput: "한계점 통과를 입력해 주세요",
        proposalValidation: "검증",
        proposalValidationInput: "검증을 입력해 주세요",
        proposalAgreeBtn: "동의",
        proposalDisagreeBtn: "동의하지 않음",
        propsalSureVote: "계속 진행하십니까",
        propsalVoteRight: "여러분의 투표권",
        propsalVoteRemind: "계속 진행하십니까 {0}?",
        propsalVoteStatusWaitStart: "기다려 주세요",
        propsalVoteStatusDoing: "투표하기",
        propsalVoteStatusEnd: "끝",
        proposalInfo: "Proposal 정보",
        proposalVoteNum: "투표 번호",
        proposalVoteResult: "투표 결과",
        proposalStatus: "투표 상태",
        editProposalConfigBtn: "Proposal설정",
        editStrategy: "편집 전략",
        validationWarning: {
          basic: {
            minScore: "제안을 제출하기 위해 {0} {1} 최소한의 양을 가지고 있어야 합니다.",
          },
          customValidation: "제안을 제출하기 위해 제안 검증을 통과해야 합니다.",
        },
        remark: "도입",
        downloadReport: "보고서 다운로드",
        completeContent: "모든 빈칸을 채워주세요.",
        endLtStart: '끝 블록 숫자는 시작 블록보다 커야 됩니다',
        blockExceed: '끝 블록 숫자는 현재 블록 숫자보다 커야 됩니다End block number must greater than current block number'
      },
      error: {
        error: "오류",
        depositFail: "입금 실패",
        withdrawFail: "출금 실패",
        changeDepsitFail: "입금 실패 변경",
        delegateFail: "임대 실패",
        changeDelegateFail: "임대 실패 변경",
        haveCreatedCommunity: "이미 커뮤니티를 만들었습니다. 재실행해 주세요!",
        approveFail: "승인 실패",
        accountChanged: "스팀과 트론 주소 잘못됨，지갑 계정이 바뀌었는지 확인해 주세요",
        delegateerror: "임대 오류！",
        tryrefreshpage: "오류，페이지를 새로고침하고 다시 시도해 주세요！",
        needkeychainandtronlink: "스팀 키체인과 트론링크를 설치해야 합니다！",
        needkeychain: "스팀 키체인을 설치해 주세요",
        needtronlink: "트론링크를 설치해 주세요",
        // insufficientEnerge: "에너지 불충분",
        transferFail: "전송 실패",
        unlockKeychain: "잠겨있는 스팀 키체인을 풀어주세요",
        // unlockWallet: "잠겨있는 트론링크를 풀어주세요",
        steemLoginFail: "스팀 로그인 실패",
        hiveLoginFail: "하이브 로그인 실패",
        // illegalTronAddress: "유효하지 않은 트론 주소",
        inputError: "잘못된 값 입력",
        inputOverflow: "초과 입력",
        inputOutOfRange: "사이에 값을 입력해 주세요 {lowerPnutAmount}-{upperPnutAmount}",
        inputLinkIllegal: "올바른 링크를 입력해 주세요 ",
        notEnoughFee: "부족한 수수료",
        insufficentPnut: "부족한 PNUT",
        insufficentErc20: "부족한 {symbol}",
        hasVoted: "포스트가 nutbox.mine에 의해 투표되었습니다",
        pleaseRetry: "다시 시도해 주세요",
        signatureFailed: "사인 실패，페이지를 새로고침하고 다시 시도해 주세요",
        serveError: "서버 오류",
        networkError: "네트워크 오류",
        noStakingFactory: "커뮤니티를 아직 만들지 않았습니다",
        blockChainError: "계약 발동 오류",
        cancelSigning: "사인 취소",
        unknow: "알수없는 오류",
        notConnectWallet: "지갑이 연결되지 않음",
        unlockWallet: "지갑을 열어주세요",
        wrongChainId: "잘못된 ChainID",
        wrongETHAddress: "잘못된 ETH 주소",
        wrongInputDevRatio: "0-100 사이 숫자를 입력해 주세요",
        notTokenContract: "입력한 주소는 BEP20 주소가 아닙니다.",
        transactionFail: "거래 실패!",
        assetHasRegisterd: "자산이 누군가에 의해 등록되었습니다.",
        steemAccountEmpty: "스팀 계정 누락",
        steemAccountActiveKeyEmpty: "스팀 계정 또는 액티브 키 누락",
        hiveAccountEmpty: "하이브 계정 누락",
        hiveAccountActiveKeyEmpty: "하이브 계정 또는 액티브 키 누락",
        // tokenDeploying: "배치 된 토큰이 있습니다, 기다리거나 페이지를 새로고침해 주세요.",
        wrongConfirmInfo: "잘못된 확인 정보!"
      },
      // navigator
      router: {
        information: "정보",
        profile: "프로필",
        asset: "자산",
        pool: "Pool",
        social: "Social 정보",
        governance: "거버넌스",
        staking: "스테이킹",
        blog: "블로그",
        member: "멤버",
        home: '홈',
        farming: '농사',
        nftStake: 'NFT 스테이크',
        nftStaking: 'NFT 스테이킹',
        nutPower: 'NUT 파워'
      },
      // placeHolder tip
      placeHolder: {
        inputName: "커뮤니티 이름을 입력해 주세요",
        inputLink: "커뮤니티의 웹사이트 링크를 입력해 주세요",
        inputDesc: "커뮤니티 설명을 입력해 주세요 ",
        inputApy: "Apr을 입력해 주세요",
        inputStartBlock: "시작 블록을 입력해 주세요",
        inputStopBlock: "스탑 블록을 입력해 주세요",
        inputMintAmount: "채굴 숫자를 입력해 주세요",
        inputDevRatio: "DAO fund ratio를 입력해 주세요",
        remarkInput: "소개를 입력해 주세요",
        confirmInfo: '확인을 "{info}" 입력해 주세요.',
        inputPoolName: 'pool 이름을 입력해 주세요.',
        inputDelegatee: '임차 받은 계정을 입력해 주세요',
        inputDevAddress: '새로운 DAO 펀드 주소를 입력해 주세요'
      },
      community: {
        community: "커뮤니티",
        social: "Social",
        communityList: "커뮤니티 리스트",
        communityTokenList: "C-Token 리스트",
        communityName: "이름",
        communityLink: "공식 링크",
        communityDesc: "설명",
        communityLogo: "로고",
        cTokenLogo: "C-Token 로고",
        communityThemeColor: "테마 색",
        communityPoster: "포스터",
        communityBalance: "커뮤니티 잔액",
        communityToken: "커뮤니티 토큰",
        communityAsset: '커뮤니티 자산',
        fundAddress: "펀드 주소",
        fundRatio: "펀드 비율",
        totalDeposit: '총 수량',
        currentBlock: '현재 블록',
        joinedCommunity: '가입된 커뮤니티',
        noJoinedCommunity: '가입된 커뮤니티 없음',
        noJoinedPool: "가입된 pool 없음",
        exploreCommunity: '커뮤니티 탐색하기',
        retainedRevenue: '보유한 수입',
        insufficientBalance: "부족한 커뮤니티 잔액",
        member: '멤버',
        daoFundInfo: 'Dao 펀드 정보',
        daoTreasuryInfo: 'Dao 재무 정보',
        choseToken: `커뮤니티 토큰으로 <span class="text-primary-0 font20"> {symbol} </span> 선택했습니다.`,
        choseTokenAsCtoken: 'cToken로 토큰을 선택',
        needTokenToCreate: `분배 이전에, 커뮤니티에 
        <span class="text-primary-0 font20"> {symbol} </span>
       충분히 입금해야 합니다`,
        chargeTip1: "지금, 또는 나중에 커뮤니티 관리 페이지에서 할수 있습니다.",
        noCommunity: "아직 커뮤니티가 없습니다",
    
        startBlock: "시작 블록",
        stopBlock: "멈춤 블록",
        mintAmount: "채굴 양",
    
        opened: 'Opened',
        closed: 'Closed'
      },
      pool: {
        updatePoolRatios: "Pools 설정",
        addPool: "Pool 추가",
        chosePoolType: 'pool 유형 고르기',
        pools: 'Pools',
        poolName: 'Pool 이름',
        noPools: '생성된 pool 없음',
        userCount: '사용자 카운트',
        totalDeposit: '총 입금',
        totalStaked: '총 스테이킹된',
        totalDelegated: '총 임대된',
        stakedPools: '스테이킹된 Pools',
        tvl: 'TVL',
        closePool: "닫기",
        ratio: '비율',
        opened: '활동 중인',
        closed: '닫은',
        activePools: '활동 중인 Pools',
        inactivePools: '비활동 중인 Pools',
        creatPool: '스테이킹 pool을 만드세요 {chainName}',
        createDelegatePool: '임대 pool을 {delegateType} 만드세요',
        createNftPool: '스테이킹 NFT(ERC1155) pool을 만드세요 {chainName}',
        yourDelegatee: '당신의 {delegateType} 임차 계정',
        ratioTip: 'Profit Sharing 비율 (비율 합은 100%)'
      },
      np: {
        nutPower: 'NUT Power（NP）',
        npDesc: "Nutbox Voting Power(NUT Power)는 Nutbox DAO의 거버넌스 파워입니다. Nutbox 거버넌스 유저 보상은 유저들의 거버넌스 활동으로 충전됩니다.",
        claimNut: 'NUT 요구',
        claimNp: 'NP 요구',
        claimAvailable: '가능한 요구：',
        availabeClaimNut: '{amount} Nut 요구하기 가능합니다.',
        addNpPool: "NP 투표 pool 추가",
        npTip1: "NUT Power 투표 pool을 추가하기 위해서, 존재하는 스테이킹 pool을 골라야 합니다. 사용자들은 NUT과 c-token을 NP 투표 pool에서 벌수 있습니다.",
        npTip2: "* NUT는 넛박스 계약에서 발행되었으며, c-token은 스테이킹 pool의 커미션으로 나옵니다.",
        npTip3: "사용가능한 NP: NP는 투표 또는 잠김해제에 사용될 수 있습니다.",
        npTip4: '투표된 NP: 여러분이 전체 Walnut pool에 투표한 NP.',
        npTip5: '잠김해제된 NP: 여러분이 잠김해제 과정에서 파워다운한 NP.',
        voteFor: '투표',
        claimbleNut: '요청된 NUT',
        claimedNut: '요청된 NUT:',
        totalNp: '총 NP',
        totalNpTip: '총 NP: 소유한 모든 NP.',
        powerDown: '파워 다운',
        powerDownTip: 'NP 에서 NUT으로 파워다운',
        powerDownTip2: '{selectRatio} 주 | 모든 {selectRatio} NP는 1 NUT으로',
        powerUpTip1: '업그레이드 NP 잠금해제 기간 {srcPeriod} 매주 {distPeriod} 마다.',
        powerUpTip2: '모든 1 NUT을 NP로 {distPeriod} 파워업',
        powerUpTip3: '당신이 선택한 잠금해제 기간에 따르면, 1 NUT에서1-64 NP로 파워업할 수 있습니다.',
        powerUpTip4: `여러분은 NUT을{nut} NP로 {np} 파워업하곤 했습니다. 이제 더 많은 NP를 얻기 위해 업그레이드할 수 있습니다. 단지 아래 블록들 중 하나를 골라주세요. 주목하세요, 잠금해제 기간은 동시에 길어질 것입니다.`,
        powerUpTip5: '1 NUT 을 {ratio} NP로',
        powerUpTip6: '{period} NP를 {ratio} NP로',
        powerUpTip7: '잠금해제 기간: {unlockTime} 몇주',
        nutStaked: "스테이킹 된 NUT:",
        npAvailable: '이용가능한 NP:',
        npVoted: '투표된 NP:',
        unlockPeriod: '잠금해제 기간:',
        unlockingNp: '잠금해제된 NP',
        unlockingPeriod: '잠금해제 기간',
        unlockInWeeks: '몇주 안에 {period} 잠금해제：',
        covertTip: '{npAmount} NP에서 {nutAmount} NUT',
        daysLeft: ' 며칠 남은',
        hoursLeft: ' 몇 시간 남은',
        minutesLeft: ' 몇 분 남은',
        secondsLeft: ' 몇 초 남은',
        upgradeTip1: `여러분이 NP를 NUT으로 다시 변경하기를 원할 때, 파워다운 작업을 해야되고 이는 점점 여러분의 NUT을 모두 얻는데 걸릴것입니다.
        <strong class="text-primary-1"> {distPeriod} weeks</strong>
        NUT을 모두 얻는데 걸릴것입니다.`
      },
      gauge: {
        voterCount: '투표자들',
        totalVoted: '총 투표된',
        vote: "투표",
        unvote: "투표안한"
      },
      asset: {
        tokenName: "이름",
        tokenSymbol: "심볼",
        tokenAddress: "토큰 주소",
        tokenId: "토큰 ID",
        wrongTokenId: "토큰 ID는 정수로 되어야 합니다.",
        price: "가격",
        totalSupply: '공급',
        cap: '시가 총액',
        distributionAmount: "Premine 양",
        notErc1155: "토큰은 ERC1155 토큰이 아닙니다."
      },
      treasury: {
        treasuryAddress: "재무부 주소",
        daoTreasury: "DAO 재무부",
        treasuryAsset: "재무 자산",
        noCreatedTip: "커뮤니티 재무부를 만들 수 있습니다.",
        createNote: "NOTE: 한번 재무부를 만들면 누구나 DAO 재무부로부터 자산을 재무부 자산으로 cToken을 이용하여 출금할 수 있습니다."
      },
      opHistory: {
        deposit: "입금 {amount} {symbol} 에 {pool}",
        depositWithName: " 입금 {amount} {symbol} 에 {pool}",
        add: "추가하세요 {amount} {symbol} 로 {delegatee} 부터 {pool}",
        addWithName: " 추가하세요 {amount} {symbol} 로 {delegatee} 부터 {pool}",
        withdraw: "출금 {amount} {symbol} 부터 {pool}",
        withdrawWithName: " 출금 {amount} {symbol} 부터 {pool}",
        sub: "마이너스 {amount} {symbol} 에 {delegatee} 부터 {pool}",
        subWithname: " 빼기 {amount} {symbol} 에 {delegatee} 부터 {pool}",
        vote: "투표 {amount} {symbol} 에 {pool}",
        voteWithName: " 투표 {amount} {symbol} 에 {pool}",
        unvote: "투표취소 {amount} {symbol} 에 {pool}",
        unvoteWithName: " 투표취소 {amount} {symbol} 에 {pool}",
        harvestGauge: "수확하기 {amount} {symbol} 부터 {pool}",
        harvestGaugeWithName: " 수확하기{amount} {symbol} 부터{pool}",
        createGauge: '새로운 투표 pool 만들기 {pool}',
        createGaugeWithName: ' 새로운 투표 pool 만들기 {pool}',
        withdrawGauge: "게이지에서 {amount} NUT 수확하기",
        withdrawGaugeWithName: " 게이지에서 {amount} NUT 수확하기",
        harvest: "수확하기{amount} {symbol} 에서{pool}",
        harvestWithName: " 수확하기{amount} {symbol} 에서{pool}",
        harvestAll: "모두 수확히기 {symbol}",
        harvestAllWithName: " 모두 수확하기 {symbol}",
        redeemTreasury: "재무부에서 소각하여 회수하기 {amount} {symbol}",
        redeemTreasuryWithName: " 재무부에서 소각하여 회수하기 {amount} {symbol}",
        create: "이런 커뮤니티를 만들어 보세요",
        createWithName: " 이런 커뮤니티를 만들어 보세요",
        setFee: "DAO 펀드 비율 변경 {amount}%",
        setFeeWithName: " DAO 펀드 비율 변경 {amount}%",
        addPool: '새로운 pool을 만들어 주세요: {pool}',
        addPoolWithName: ' 새로운 pool을 만들어 주세요: {pool}',
        closePool: "pool 닫기: {pool}",
        closePoolWithName: " pool 닫기: {pool}",
        setRatio: "pool 비율 리셋하기",
        setRatioWithName: " pool 비율 리셋하기",
        setDaofund: "DAO 펀드 주소 리셋하기 : {address}",
        setDaofundWithName: " DAO 펀드 주소 리셋하기: {address}",
        withdrawRevenue: "수입 출금하기 {address}",
        withdrawRevenueWithName: " 수입 to {address}",
        createTreasury: "재무부 생성하기: {address}",
        createTreasuryWithName: " 재무부 생성하기: {address}",
        title: {
          DEPOSIT: "입금",
          WITHDRAW: '출금',
          VOTE: "투표 pool",
          UNVOTE: "투표취소 pool",
          WITHDRAWGAUGENUT: "NP투표로 NUT 수확하기",
          ADMINCREATENEWGAUGE: "투표 pool 만들기",
          WITHDRAWGAUGECTOKEN: "NP투표로 C-Token 수확하기",
          ADMINWITHDRAWGAUGENUT: "NP투표로 NUT 수확하기",
          HARVEST: "수확하기",
          HARVESTALL: "모두 수확하기",
          REDEEMFROMTREASURY: "재무부로부터 회수하기",
          ADMINCREATE: "커뮤니티 만들기",
          ADMINSETFEE: "DAO 펀드 비율 설정하기",
          ADMINADDPOOL: '새로운 pool 만들기',
          ADMINCLOSEPOOL: "Pool 닫기",
          ADMINSETRATIO: "비율 리셋하기",
          ADMINSETDAOFUND: "Reset DAO 펀드 리셋하기",
          ADMINWITHDRAWNREVENUE: "수입 출금하기",
          ADMINCREATETREASURY: "재무부 만들기"
        }
      }
    };