module.exports = {
  commen: {
    steemAccoutPlaceHolder: "Steem アカウント",
    hiveAccountPlaceHolder: "Hive アカウント",
    loginByKeychain: "Keychainでログイン",
    loging: "ログイン中...",
    crowdstaking: "ステーキング",
    optional: "(オプション)",
    connectMetamask: 'Metamaskと連携',
    delegateFee: '委任手数料',

    apy: "APR",
    balance: "残高",
    max: "最大",
    complete: "完了",
    now: "今",
    poster: "ポスター",
    more: 'もっと',
    detail:'詳細',
    activities: 'アクティビティ',
    administor: '管理者',
    joinDate: '加入日',
    noData: 'データなし',
    pageNotFound: "ページが見つかりません!",
    backHome: "ホームへ戻る",
    amount: "量",
    earned: '収益',
    voted: "投票",
    staked: "担保",
    delegated: "委任",
    totalStake: '総担保',
    voters: '投票者',
    stakers: '担保者',
    summary: '概要',
    weeks: '週',
    or: 'また',

    whitePaper: "ホワイトペーパー",
    nps: "Nutbox 提案システム",
    contract: "契約",
    social: "SNS情報",
    docs: "ドキュメント",
    discord: "Discord",
    blog: "ブログ",
    contractAddr: "コントラクトアドレス",
    dashboard: "ダッシュボード",
    auditReport: "監査報告",
    language: "言語",
    zh: "中文",
    en: "English",
    kr: "한국어",
    my: "Malaysia",
    tr: "Türkçe",
    es: "Español",
    jp: "日本語",
    goToHome: "ホームへ戻る",
    home: 'ホーム',
  },
  desc: {
    slogan: '数分であなたのステーキングエコノミーを作成します',
    featuredCommunity: '注目のコミュニティ',
    aboutNutbox: 'Nutboxについて',
    totalValue: '総資産価値',
    disStrategy: '配布ステージ',
    adminOnly: '管理者のみ',
    youWillReceive: 'あなたがもらえる: ',
    iso: 'ISO: 最初のステーキングオファリング',
    isoDetail: 'ステークPoSトークンミントコミュニティトークン。高いAPR、リスクなし。',
    erc1155Desc: 'ERC1155トークンを担保して収益がもらえる。',
    nutPower: 'Nut powerは、NutboxDAOの管理権です。NUTをステーキングすることによって生成され、NUTがロックされる時間が長いほど、より多くのNut powerが生成されます。また、新しいNUTの配布を決定するためのコアファクターでもあります。',
    farming: 'トークンを担保して稼げ。',
    assetTip1: '既存のトークンを使用する場合',
    assetTip2: '新しいトークンを使用する場合',
    assetTipe3: `*いくつかのトークンを事前に準備して、コミュニティを起動できます。
    残りのトークンは、ブロックごとに契約から作成されます.`
  },
  operation: {
    withdraw: "出金",
    harvest: "収穫",
    approveContract: "契約を承認する",
    approveCommunity: 'コミュニティを承認する',
    approvePool: 'コミュニティを承認する',
    connectMetamask: "Metamaskと連携",
    change: '変更',
    search: "検索",
    userlogin: "ログイン",
    uploadLogo: "ロゴアップロード",
    uploadPoster: "ポスターアップロード",
    sign: "署名してアップロード",
    hide: '隠す',
    cancel: "キャンセル",
    commit: "コミット",
    complete: "完了",
    confirm: "確認",
    create: "作成",
    add: "追加",
    update: "更新",
    delegatecharge: "委任料",
    login: "ログイン",
    logout: "ログアウト",
    input: "入力",
    ok: 'OK',
    copy: "コピー",
    edit: "編集",
    charge: 'チャージ',
    redeem: "償還",
    enter: 'エンター',
    approve: '承認',
    grant: '協賛者',
    manageCommunity: "コミュニティ管理",
    exploreCommunity:'コミュニティを探索',
    save: '保存',
    grantMintRole: 'マイニング許可を与える',
    configurePoolRatio: '委任プールを構成',
    addDelegatePool: '委任プールを追加',
    addPool: 'プール追加',
    claim: '受取',
    recharge: "チャージ",
    upgrade: 'アップグレード',
    choseToken: "トークンを選択する",
    registerAsset: "資産登録",
    deploy: '配備'
  },
  wallet: {
    wallet: "ウォレット",
    connectSteem: "Steemウォレットと連携",
    connectHive: "Hiveウォレットと連携",
    connectCosmos: "Keplrウォレットと連携",
    install: "インストール",
    transfer: "移行",
    asset: "資産",
    nomination: "投票委任者 ",
    contribution: "クラウドローン",
    balance: "残高",
    bond: "担保",
    unBond: "担保解除",
    redeem: "償還",
    bindAmount: "担保量",
    inputAmount: "担保量を入力",
    receiveAddress: "受取者アドレス",
    transferAmount: "移行量",
    unbindAmount: "担保解除量",
    intallKeplr: "keplrをインストールしてください。"
  },
  date: {
    year: " 年 ",
    month: " 月 ",
    day: " 日 ",
    hour: " 時 ",
    min: " 分 ",
    sec: " 秒 ",
  },
  transaction: {
    broadcasting: "トランザクションはブロードゥキャスティング",
    inBlock: "トランザクションはblockHashに記入された",
    contributeOk: "投票完了!",
    withdrawOk: "出金完了!",
    nominateOk: "推薦完了!",
    transactionOk: "トランザクション完了!",
    depositOk: "入金完了",
    withdrawOk: "担保量は正常に減らした!",
    voteOk: '投票完了',
    unvoteOk: "投票取消完了",
    
  },
  stake: {
    stake: "担保マイニング",
    creaseDelegation: "委任",
    increaseDelegation: "委任解除",
    getSp: "SPゲット",
    get: "ゲット",
    stake: "入金",
    unStake: "入金取消",
    staked: "担保済",
    changeAccount: ' {type} アカウントを変更してください',
    changeAccountHtml: `
    <span class="text-primary-0 font-bold">{type}</span>
    アカウントを変更してください`,
    changeAddress: ' {chainName} アドレスを変更してください。',
    changeAddressHtml: `
    <span class="text-primary-0 font-bold"> {chainName} </span>アドレスを変更してください`,
    accountMismatch1: `あなたの {type} アカウントは現在の {chainName}アドレスと連携されていません。自分のウォレットで {type} アカウントを変更してください `,
    accountMismatch2: `あなたの {chainName} アドレスは現在の  {type} アカウントと連携されていません。自分のウォレットで {chainName} アドレスを変更してください`,
    accountMismatch3: `あなたの
    <span class="text-primary-0 font-bold">{type}</span>アカウントは現在の
    <span class="text-primary-0 font-bold">{chainName}</span>
    アドレスと連携されていません。
   自分のウォレットで <span class="text-primary-0 font-bold">{type}</span>
    アカウントを変更してください。`,
    accountMismatch4: ` あなたの <span class="text-primary-0 font-bold"> {chainName} </span>
    アドレスは現在の
    <span class="text-primary-0 font-bold"> {type}
    </span>
    アカウントと連携されていません。自分のウォレットで
    <span class="text-primary-0 font-bold"> {chainName} </span>
    アドレスを変更してください。`,
    bindAccountTip1: `あなたの連携{type}アカウントは :`,
    bindAddressTip1: 'あなたの連携アドレスは:'
  },
  tip: {
    approveSuccess: "承認済",
    loading: "読み込み中, 少々お待ちください。",
    connectingPolkadot: "Polkadotネットワークに接続中",
    noAuction: "進行中のオークションはありません ",
    noProject: "進行中のプロジェクトはありません",
    noNominations: "ノミネーターノードなし",
    noContribuitons: "投票履歴はありません",
    noCommunities: "登録されたコミュニティはありません ",
    noSearchedToken: "検索されたトークンはありません",
    noCrowdloan: "進行中のクラウドローンはありません",
    noStakingProject: "バリデーターなし",
    picTip: "推奨サイズは{size}、2M未満、jpg、png、jpeg形式は対応しています。",
    tokenSafeTip: "あなたの資産は、Nutoboxのアカウントではなく、{symbol}の公式クラウドローンパレットに直接ロックされます。",
    belowMinContribution: "入力されたのは必要な {min}投票量より少ない",
    belowMinBond: "入力されたのは最小推奨担保量0.1未満です",
    wrongNominatorAddress: "間違った推薦者のアドレス",
    largeImg: 'Image size exceeds 2M',
    wrongTransferAddress: "間違った移行のアドレス",
    inputAddressType: "{relaychain}アドレスを入力してください。",
    insufficientBalance: "残高不足",
    editTip: "コミュニティ情報をアップロードするには、ウォレットの署名を使用する必要があります。この操作には費用はかかりませんので、ご安心ください。",
    outOfCap: "上限を超えています",
    tips: "ヒント",
    error: "エラー！",
    success: "完了!",
    warning: "警告！",
    noNeedWithdraw: "出金する必要はありません",
    comingSoon: "近日公開",
    clipboard: "クリップボード",
    copyAddress: "コピーされたアドレス:  {address}",
    signing: "署名",
    picUploadFail: "写真のアップロードは失敗しました。もう一度お試しください",
    outOfUsage: 'Upload so frequently, please try later.',
    uploadLogo: "ロゴをアップロードしてください",
    deloyTokenFail: "トークンのデルポイに失敗しました。もう一度お試しください",
    notContractAddress: "デプロイトークン契約アドレスを提供してください",
    registryAssetFail: "資産登録失敗",
    wrongStartBlockNum: "開始ブロックナンバーは現在のBlockNumよりも大きくする必要があります",
    wrongStopBlockNum: "終了ブロックナンバーは開始ブロックナンバーよりも大きくする必要があります",
    youHaveCreatedCommunity: "コミュニティを作成しました",
    pleaseFillData: "デルポイの資産と配布ポリシーを選択してください",
    deployFactoryFail: "ファクトリーはデルポイに失敗しました。もう一度お試しください",
    deploySuccess: "デルポイ完了",
    createPoolSuccess: "プール作成完了",
    updatePoolSuccess: 'プール比率更新完了',
    chargeSuccess: 'チャージ完了',
    storeTokenAddress: "情報を確認し、トークンアドレスをすぐに保存してください",
    needName: "コミュニティ名を入力してください",
    needWebsit: "コミュニティウェブサイトを入力してください",
    needDescription: "コミュニティの説明を入力してください",
    needIcon: "コミュニティロゴをアップロードしてください",
    needTokenIcon: "C-トークンロゴをアップロードしてください",
    needPoster: "コミュニティポスターをアップロードしてください",
    needTokenName: "トークン名を入力してください",
    needTokenSymbol: "トークン記号を入力してください",
    completeCommunityInfoSuccess: "コミュニティ情報が正常に更新されました",
    wrongSubstrateAddress: "正しい {type} アドレスを入力してください",
    ratioError: "担保プールの合計比率は100です",
    wrongApy: "正しいAPRを入力してください",
    wrongRewardNum: "正しい報酬を入力してください",
    deployCommunitySuccess: "デルポイ完了!",
    communityNameLimit: "コミュニティ名は{count}文字を超えることはできません",
    descriptionLimit: "コミュニティの説明は{count}文字を超えることはできません",
    needRightUrl: "正しいURLを入力してください.",
    selectCowdloan: "クラウドローンプロジェクトを選択してください。",
    inputRightBlogTag: "正しいSteemコミュニティタグを入力してください。 eg:hive-12345.",
    withdrawSuccess: "出金完了!",
    harvestSuccess: "収穫完了!",
    deploying: "デルポイ中です。少し時間かかりますので、しばらくお待ちください。",
    stopPoolOk: "プールは停止されました",
    removePoolOk: "プールは削除されました",
    tryWithdrawOk: "償還完了。",
    stopPoolTips: "プールを停止する前に、プール比率を０に設定することは必要です。",
    completeCommunityProposalConfigSuccess: "コミュニティ提案作成完了",
    completeProposalSuccess: "提案完了",
    completeVoteSuccess: "投票完了",
    needGameName: "ゲーム名を入力してください",
    gameNameLimit: "ゲーム名は{count}文字を超えることはできません",
    needGameType: "ゲームの種類を選択してください",
    completeGameSuccess: "ゲーム完了",
    deployTokenTip: '注意：これは、総供給量ではなく、自分のアカウントへの事前配布量のミントです。注意してください。',
    attention: "注意!",
    markdownTip: "マークダウン形式を使用できます。",
    stopPoolAttention: 'プールを閉じると、再び開くことはできません。 この機能は注意して使用してください!',
    stakeTokenLogoTip: "このロゴは、選択した担保トークン{token}に設定されています。",
    updateSocialSuccess: '更新完了!',
    startHeightOut: '最初の開始ブロックは、現在のブロックよりも大きくする必要があります。',
    wrongSocialInfo: "正しい{type}リンクを入力してください!",
    noPowerupNut: "Haven't power up NUT",
    needAscii: '入力できるのは単純な文字のみです',
    npRedeemProcess: '償還プロセスはありません。',
    powerDownSuccess: 'パワーダウン完了',
    redeemSuccess: "償還完了",
    mintableTip1: 'コミュニティを作成するときに、c-Tokenをミントできるように設定しました。',
    mintableTip2: "今コミュニティコントラストにマイニング許可を与えなければならない。そうしないと、このコミュニティの機能を使用できません",
    delegateTip1: " {chain}アカウントを利用中: {account} に委任する。",
    rechargeTip1: "後で再チャージ",
    feeTip: '操作費: ${fee} NUT'
  },
  nps: {
    nps: "管理",
    npsTemp: "Peanutの管理を例として示します。 Walnut の管理はまだ開発中です。",
    all: "すべて",
    pass: "承認済",
    pending: "保留中",
    unpass: "拒否",
    rolling: "投票",
    mine: "My publish",
    title: "タイトル",
    titleInputTip: "タイトルを入力してください",
    startAndEndTime: "開始時間と終了時間",
    startTime: "開始時間",
    endTime: "終了時間",
    details: "詳細",
    detailsInputTip: "提案内容を入力してください。",
    proposalConfigLabel: "提案構成",
    voteProposal: "投票提案",
    createProposal: "投票作成",
    noProposals: "提案なし",
    proposalTitle: "タイトル",
    proposalTitleInput: "提案のタイトルを入力してください。",
    proposalStart: "開始時間",
    proposalEnd: "終了時間",
    proposalFirst_Block: "開始ブロック",
    proposalEnd_Block: "終了ブロック",
    proposalBody: "詳細",
    proposalBodyPreview: "プレビュー",
    proposalBodyInput: "詳細を入力してください",
    proposalNetwork: "ネットワーク",
    proposalNetworkInput: "ネットワークを選択してください",
    proposalSymbol: "記号",
    proposalSymbolInput: "記号を入力してください",
    proposalStrategies: "戦略",
    proposalThreshold: "スレッショルド",
    proposalThresholdInput: "スレッショルドを入力してください",
    proposalPassThreshold: "パスしきい値",
    proposalPassThresholdInput: "パスしきい値を入力してください",
    proposalValidation: "バリデーション",
    proposalValidationInput: "バリデーションを入力してください",
    proposalAgreeBtn: "同意",
    proposalDisagreeBtn: "拒否",
    propsalSureVote: "確認しますか",
    propsalVoteRight: "投票権",
    propsalVoteRemind: "確認しますか {0}?",
    propsalVoteStatusWaitStart: "お待ちください",
    propsalVoteStatusDoing: "投票中",
    propsalVoteStatusEnd: "終了",
    proposalInfo: "提案情報",
    proposalVoteNum: "投票ナンバー",
    proposalVoteResult: "投票結果",
    proposalStatus: "投票ステータス",
    editProposalConfigBtn: "提案構成",
    editStrategy: "戦略編集",
    validationWarning: {
      basic: {
        minScore: "提案を提出するには、最低{0}{1}が必要です。",
      },
      customValidation: "提案を提出するには、提案のバリデーションに合格する必要があります。",
    },
    remark: "紹介",
    downloadReport: "レポートをダウンロード",
    completeContent: "すべての空欄を記入してください。",
    endLtStart: '終了ブロック番号は開始ブロックより大きくなければなりません',
    blockExceed: '終了ブロック番号は現在のブロック番号より大きくなければなりません'
  },
  error: {
    error: "エラー",
    depositFail: "入金失敗",
    withdrawFail: "出金失敗",
    changeDepsitFail: "入金変更失敗",
    delegateFail: "委任失敗",
    changeDelegateFail: "委任変更失敗",
    haveCreatedCommunity: "すでにコミュニティを作成しました。更新してみてください!",
    approveFail: "承認失敗",
    accountChanged: "SteemとTronアドレスは一致しません。ウォレットアカウントが切り替えられているかどうかを確認してください",
    delegateerror: "委任エラー！",
    tryrefreshpage: "エラー、ページを更新して再試行してください！",
    needkeychainandtronlink: "Steem　KeychainとTronLinkをインストールする必要があります！",
    needkeychain: "Steem Keychainをインストールしてください。",
    needtronlink: "TronLinkをインストールしてください。",
    insufficientEnerge: "Not enough energy",
    transferFail: "転送失敗",
    unlockKeychain: "Steem Keychainのロックを解除してください",
    unlockWallet: "tronlinkのロックを解除してください",
    steemLoginFail: "Steemログイン失敗",
    hiveLoginFail: "Hiveログイン失敗",
    illegalTronAddress: "無効なtronアドレス",
    inputError: "間違った値を入力した",
    inputOverflow: "入力オーバーフロー",
    inputOutOfRange: " {lowerPnutAmount}-{upperPnutAmount}間に値を入力してください",
    inputLinkIllegal: "正しいリンクを入力してください",
    notEnoughFee: "料金不足",
    insufficentPnut: "PNUT不足",
    insufficentErc20: "{symbol}不足",
    hasVoted: "あなたの投稿はnutbox.mineによって投票されました",
    pleaseRetry: "もう一度お試しください",
    signatureFailed: "署名に失敗しました。ページを更新して再試行してください",
    serveError: "サーバーエラー",
    networkError: "ネットワークエラー",
    noStakingFactory: "まだコミュニティを作成していません",
    blockChainError: "コントラスト要請エラー",
    cancelSigning: "署名キャンセル",
    unknow: "未知のエラー",
    notConnectWallet: "ウォレットが連携されていません",
    unlockWallet: "ウォレットのロックを解除してください",
    wrongChainId: "間違ったChainID",
    wrongETHAddress: "間違ったETH アドレス",
    wrongInputDevRatio: "0〜100の数字を入力してください",
    notTokenContract: "入力されたのはBEP20コントラクトアドレスではありません。",
    transactionFail: "トランザクション失敗!",
    assetHasRegisterd: "資産が誰かによって登録されました.",
    steemAccountEmpty: "Steemアカウントが見つかりません",
    steemAccountActiveKeyEmpty: "Steemアカウントまたはアクティブキーが見つかりません",
    hiveAccountEmpty: "Hiveアカウントが見つかりません",
    hiveAccountActiveKeyEmpty: "Hiveアカウントまたはアクティブキーが見つかりません",
    tokenDeploying: "只今トークンは配備中です、しばらく待つかページを更新してみてください。",
    wrongConfirmInfo: "間違った確認情報!"
  },
  // navigator
  router: {
    information: "情報",
    profile: "プロフィール",
    asset: "資産",
    pool: "プール",
    social: "SNS情報",
    governance: "管理",
    staking: "担保",
    blog: "ブログ",
    member: "メンバー",
    home: 'ホーム',
    farming: 'ファーミング',
    nftStake: 'NFT担保',
    nftStaking: 'NFT担保',
    nutPower: 'NUT Power'
  },
  // placeHolder tip
  placeHolder: {
    inputName: "コミュニティ名を入力してください",
    inputLink: "コミュニティウェブサイトリンクを入力してください",
    inputDesc: "コミュニティの説明を入力してください ",
    inputApy: "Aprを入力してください",
    inputStartBlock: "開始ブロックを入力してください",
    inputStopBlock: "終了ブロックを入力してください",
    inputMintAmount: "ミント番号を入力してください",
    inputDevRatio: "DAOファンド比率を入力してください",
    remarkInput: "紹介を入力してください",
    confirmInfo: ' "{info}"入力して確認ください。 ',
    inputPoolName: 'プール名を入力してください。',
    inputDelegatee: '委任先アカウントを入力してください',
    inputDevAddress: '新しいDAOファンドのアドレスを入力してください'
  },
  community: {
    community: "コミュニティ",
    social: "ソーシャル",
    communityList: "コミュニティリスト",
    communityTokenList: "C-Tokenリスト",
    communityName: "名前",
    communityLink: "公式リンク",
    communityDesc: "説明",
    communityLogo: "ロゴ",
    cTokenLogo: "C-Tokenロゴ",
    communityThemeColor: "テーマカラー",
    communityPoster: "ポスター",
    communityBalance: "コミュニティ残高",
    communityToken: "コミュニティトークン",
    communityAsset: 'コミュニティ資産',
    fundAddress: "ファンドアドレス",
    fundRatio: "ファンド比率",
    totalDeposit: '総量',
    currentBlock: '現在のブロック',
    createCommunity: 'コミュニティ作成',
    manageCommunity: 'コミュニティ管理',
    communityDashboard: "コミュニティダッシュボード",
    communtiyDesc: "DeFi、ソーシャルメディア、管理をコミュニティにもたらすのは簡単です",
    joinedCommunity: '加入したコミュニティ',
    noJoinedCommunity: '加入したコミュニティはありません',
    noJoinedPool: "加入したプールはありません",
    exploreCommunity: 'コミュニティを探索する',
    retainedRevenue: '保留収入',
    insufficientBalance: "コミュニティ残高不足",
    member: 'メンバー',
    daoFundInfo: 'Daoファンド情報',
    daoTreasuryInfo: 'Dao財務情報',
    choseToken: `コミュニティトークンとして<span class="text-primary-0 font20"> {symbol} </span>を選択しました。`,
    choseTokenAsCtoken: 'cTokenとしてトークンを選択します',
    needTokenToCreate: `配布する前に、十分な
    <span class="text-primary-0 font20"> {symbol} </span>
    入金が必要です。`,
    chargeTip1: "今すぐ、または後でコミュニティ管理ページで実行できます。",
    noCommunity: "まだコミュニティに加入していません",
    choseAsset: '資産を選択する',
    deployAsset: 'コミュニティ資産を配備する',
    completeInfo: '全ての情報',

    startBlock: "開始ブロック",
    stopBlock: "終了ブロック",
    mintAmount: "ミント料",

    opened: '開放した',
    closed: '終了した'
  },
  pool: {
    updatePoolRatios: "プール設定",
    addPool: "プール追加",
    chosePoolType: 'プールタイプ選択',
    pools: 'プール',
    poolName: 'プール名',
    noPools: '作成したプールはありません',
    userCount: 'ユーザー数',
    totalDeposit: '総入金',
    totalStaked: '総担保',
    totalDelegated: '総委任',
    stakedPools: '担保プール',
    tvl: 'TVL',
    closePool: "閉じる",
    ratio: '比率',
    opened: 'アクティブ',
    closed: '閉鎖',
    activePools: 'アクティブプール',
    inactivePools: '非アクティブプール',
    creatPool: '{chainName}でプールを作成する',
    createDelegatePool: '{delegateType}委任プールを作成',
    createNftPool: '  {chainName}で担保 NFT(ERC1155)プールを作成する',
    yourDelegatee: ' {delegateType} 委任アカウント',
    ratioTip: '利益分配率 (合計比率は100％です)'
  },
  np: {
    nutPower: 'NUT Power（NP）',
    npDesc: "Nutbox投票権(略してNUT)はNutbox DAOの管理パワーです。Nutbox管理報酬は、ユーザーの管理表現によって配布されます。 ",
    claimNut: 'NUT受取',
    claimNp: 'NP受取',
    claimAvailable: '受取できる：',
    availabeClaimNut: '{amount} Nutを受取るできる.',
    addNpPool: "NP投票プール追加",
    npTip1: "NUT Power投票プールを追加するには、既存の担保プールを選択する必要があります。ユーザーは、NP投票プールからNUTとc-tokenを獲得できます",
    npTip2: "* NUTはNutbox契約によって発行され、c-tokenは担保プールの手数料から発行されます。",
    npTip3: "利用可能な NP: NPは、投票またはロック解除に使用できます",
    npTip4: '投票されたNP: Walnut全体のプールに投票したNP',
    npTip5: 'ロック解除NP: ロック解除プロセスで利用したNP',
    voteFor: '投票',
    claimbleNut: '請求できるNUT',
    claimedNut: '請求したNUT:',
    totalNp: '総NP',
    totalNpTip: '総NP: 所有するすべてのNP。',
    powerDown: 'パワーダウン',
    powerDownTip: 'NPをNUTにパワーダウンします',
    powerDownTip2: '{selectRatio} 週 | 毎{selectRatio} NPから1 NUTへ',
    powerUpTip1: 'NPロック解除期間を {srcPeriod}週から {distPeriod} 週へアップグレード.',
    powerUpTip2: '1 NUTごとに {distPeriod} NPをPower up',
    powerUpTip3: '選択したロック解除期間に応じて, 1NUTを1-64NPにパワーアップできます',
    powerUpTip4: `以前は、{nut}NUTを{np}NPにパワーアップしていました。これで、それらをアップグレードして、より多くのNPを取得できます。以下のブロックの1つを選択してください。ロック解除期間は同時に成長することをご注意してください。`,
    powerUpTip5: '1 NUT から {ratio} NPへ',
    powerUpTip6: '{period} NPから {ratio} NPへ',
    powerUpTip7: 'ロック解除期間: {unlockTime} 週',
    nutStaked: "NUT担保:",
    npAvailable: 'NP利用可能:',
    npVoted: 'NP投票:',
    unlockPeriod: 'ロック解除期間:',
    unlockingNp: 'NPロック解除',
    unlockingPeriod: 'ロック解除期間',
    unlockInWeeks: '{period} 週間以内に解除済：',
    covertTip: '{npAmount} NPから {nutAmount} NUTへ',
    daysLeft: ' 日残り',
    hoursLeft: ' 時間残り',
    minutesLeft: ' 分残り',
    secondsLeft: ' 秒残り',
    upgradeTip1: `NPをNUTに戻す場合は、パワーダウン操作を行う必要があります。すべてのNUTを徐々に元に戻すは 
    <strong class="text-primary-1"> {distPeriod} 週</strong>
  かかります.`
  },
  gauge: {
    voterCount: '投票者',
    totalVoted: '総投票数',
    vote: "投票",
    unvote: "未投票"
  },
  asset: {
    tokenName: "名前",
    tokenSymbol: "記号",
    tokenAddress: "トークンアドレス",
    tokenId: "トークン ID",
    wrongTokenId: "トークンIDは整数である必要があります。",
    price: "価格",
    totalSupply: '供給',
    cap: '時価総額',
    distributionAmount: "事前ミント数",
    notErc1155: "ERC1155トークンではありません。",
    wrongAddress: '間違ったアドレス',
    choseCToken: 'cTokenとしてトークンを選択してください。',
    setDistr: '資産配分の設定',
    totalDistr: '現行のポリシーによる総分配',
    currentBlock: '現在のブロックの高さ',
    tip1: '選択したトークンはミントできます。',
    tip2: '便利のために、コミュニティ契約にミント権限を与えることができます。そうすれば、将来、毎回トークンをコミュニティ契約に加入する必要がなくなります。',
    tip3: 'このオプションを一旦設定すると、今後変更することはできませんのでご注意ください。',
    tip4: "メカニズムがわからない場合は、チェックボックスをオフのままにしてください。",
    promiseGrant: 'はい、後でコミュニティ契約にミントの役割を付与します。',
    tip5: 'コミュニティ契約が正常に展開されました。',
    tip6: '契約にミントの役割を付与することを確認してください。'
  },
  treasury: {
    treasuryAddress: "トレジャリーアドレス",
    daoTreasury: "DAOトレジャリー",
    treasuryAsset: "トレジャリー資産",
    noCreatedTip: "自分のコミュニティのトレジャリーを作成することができます.",
    createNote: "注意：トレジャリーを作成すると、誰でもDAOトレジャリーから資産を引き換えることができ、cTokenをトレジャリー資産のシェアとして使用できます。"
  },
  opHistory: {
    deposit: " {amount} {symbol}を{pool}へ入金",
    depositWithName: " deposit {amount} {symbol} を {pool}へ入金",
    add: "{pool}から{delegatee}へ {amount} {symbol} を追加 ",
    addWithName: " {pool}から{delegatee}へ {amount} {symbol} を追加",
    withdraw: "{pool}から{amount} {symbol}を出金",
    withdrawWithName: "{pool}から{amount} {symbol}を出金",
    sub: "{pool}から{delegatee}へ {amount} {symbol} を減らす",
    subWithName: "{pool}から{delegatee}へ {amount} {symbol} を減らす",
    vote: "{pool}へ{amount} {symbol}を投票",
    voteWithName: " {pool}へ{amount} {symbol}を投票",
    unvote: "{pool}へ{amount} {symbol}を投票取消",
    unvoteWithName: " {pool}へ{amount} {symbol}を投票取消",
    harvestGauge: "{pool}から{amount} {symbol}を収穫",
    harvestGaugeWithName: "{pool}から{amount} {symbol}を収穫",
    createGauge: '{pool}の新しいプール投票を作成します',
    createGaugeWithName: '{pool}の新しいプール投票を作成します',
    withdrawGauge: "ゲージから {amount} NUTを収穫",
    withdrawGaugeWithName: "ゲージから {amount} NUTを収穫",
    harvest: "{pool}から{amount} {symbol}を収穫",
    harvestWithName: "{pool}から{amount} {symbol}を収穫",
    harvestAll: "収穫全ての{symbol}",
    harvestAllWithName: " 収穫全ての{symbol}",
    redeemTreasury: "{amount}{symbol}を燃えるによってトレジャリーから償還する",
    redeemTreasuryWithName: "{amount}{symbol}を燃えるによってトレジャリーから償還する",
    create: "このコミュニティを作成する",
    createWithName: " このコミュニティを作成する",
    setFee: "DAOファンド比率を {amount}%に変更",
    setFeeWithName: " DAOファンド比率を {amount}%に変更",
    addPool: '新しいプール作成: {pool}',
    addPoolWithName: ' 新しいプール作成: {pool}',
    closePool: "プールを閉じる: {pool}",
    closePoolWithName: " プールを閉じる: {pool}",
    setRatio: "プール比率をリセット",
    setRatioWithName: " プール比率をリセット",
    setDaofund: "DAOファンドアドレスをリセット : {address}",
    setDaofundWithName: " DAOファンドアドレスをリセット: {address}",
    withdrawRevenue: "{address}へ報酬出金",
    withdrawRevenueWithName: "{address}へ報酬",
    createTreasury: "トレジャリー作成: {address}",
    createTreasuryWithName: " トレジャリー作成: {address}",
    title: {
      DEPOSIT: "入金",
      WITHDRAW: '出金',
      VOTE: "投票プール",
      UNVOTE: "未投票プール",
      WITHDRAWGAUGENUT: "NP投票によるNUTの収穫",
      ADMINCREATENEWGAUGE: "プール投票を作成する",
      WITHDRAWGAUGECTOKEN: "NP投票によるCトークンの収穫",
      ADMINWITHDRAWGAUGENUT: "NP投票によるNUTの収穫",
      HARVEST: "収穫",
      HARVESTALL: "全ての収穫",
      REDEEMFROMTREASURY: "トレジャリーからの償還",
      ADMINCREATE: "コミュニティ作成",
      ADMINSETFEE: "DAOファンド比率設定",
      ADMINADDPOOL: '新しいプール作成',
      ADMINCLOSEPOOL: "プールを閉じる",
      ADMINSETRATIO: "プール比率をリセット",
      ADMINSETDAOFUND: "DAOファンドをリセット",
      ADMINWITHDRAWNREVENUE: "報酬出金",
      ADMINCREATETREASURY: "トレジャリー作成"
    }
  }
};